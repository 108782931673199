<template v-if="formActive">
    <template v-if="showType == 'unit'">
        <template v-if="log && log.info">
            <div class="fw-bold mb-2">Info</div>
            <pre>{{log.info}}</pre>
            <hr />
            <div class="fw-bold mb-2">Request</div>
        </template>
        <div style="border-bottom: 1px solid #dee2e6;" class="mb-2" v-if="log && log.external_api_log_lines">
            <pre>{{ JSON.stringify(JSON.parse(log.external_api_log_lines.data), null, 2) }}</pre>
        </div>
    </template>
    <template v-else-if="showType == 'full'">
        <div style="border-bottom: 1px solid #dee2e6;" class="mb-2" v-if="log.request_body">
            <pre>{{ JSON.stringify(JSON.parse(log.request_body), null, 2) }}</pre>
        </div>
    </template>
    <template v-else>
        <FormItem id="created_at" label="Date" v-model="log.created_at" :disabled="true" />
        <!-- <FormItem id="uri" label="URI" v-model="log.uri" :disabled="true" /> -->
        <FormItem id="controller" label="Module" v-model="log.class" :disabled="true" />
        <FormItem id="method" label="Action" v-model="log.function" :disabled="true" />
        <FormItem v-if="log.model_id" id="model_id" label="Model ID" v-model="log.model_id" :disabled="true" />
        <FormItem v-if="log.external_api_user" id="user" label="User" v-model="log.external_api_user.description" :disabled="true" />
        <FormItem id="user_agent" label="User agent" v-model="log.user_agent" :disabled="true" />
        <FormItem id="ip" label="IP" v-model="log.ip" :disabled="true" />
        <FormItem id="body" label="Request" v-model="log.request_body" type="textarea" :disabled="true" />
        <FormItem v-if="log.info" id="info" label="Info" v-model="log.info" type="textarea" :options="5" :disabled="true" />

        <br>
        <div class="mb-2" v-if="log.request_body">
            <pre>{{ JSON.stringify(JSON.parse(log.request_body), null, 2) }}</pre>
        </div>
    </template>
</template>

<script>
    export default {
        name: 'LogForm',
        props: ['itemData', 'showType'],
        data() {
            return {
                log: {user: {}},
                submitted: false,
                loading: false,
                invalid: {},
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.log = val    
            },
        },
        methods: {
            removeEntry:function(data) {
                let logdata = JSON.parse(this.log.request_body)
                console.log(logdata);
                delete logdata['Header']['Partner']
                delete logdata['Header']['Receiver']
                delete logdata['Header']['Creation_Date']
                delete logdata['Header']['Message_Sub_Type']
                return logdata;
            },
            active(value){
                this.formActive = value;
            },
            validate(){
                let valid = true;
                let required = document.querySelectorAll('#formModal input[required],#formModal select[required]');

                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select')) {
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(valid) valid = this.log.name;
                if(!valid) alert(this.$t('errors.empty_fields'));
                return valid;
            },
            getData() {
                return this.log;
            },
            setData(data) {
                this.log = data
                this.log.formatted_date = this.formatDate(this.log.date, 'dateTime');
            },
            reset() {
                this.log = {user: {}}
                this.loading = false
                this.invalid = {}
            }
            
        }
    }
</script>
